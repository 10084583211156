<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          class="demo-form-inline"
          :rules="rule"
          :model="formInline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" ref="plateNumber">
                <el-autocomplete
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  value-key="plateNumber"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>

              <el-form-item label="所在道路">
                <my-component
                  ref="parkInput"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Entry_time')">
                <el-date-picker
                  :editable="false"
                  v-model.trim="dateRange"
                  type="daterange"
                  placeholder="请选择时间范围"
                  @change="dateFmt(dateRange)"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searParkRecordList();
                "
                v-if="$route.meta.authority.button.query"
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <el-button type="info" icon="el-icon-setting" @click="addjkSeting">{{ $t('button.set_up') }}</el-button>
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="80"
            type="index"
            v-if="$route.meta.authority.button.detail"
          >
            <template slot-scope="scope">
              <el-dropdown @command="detailShow(scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-if="$route.meta.authority.button.detail"
                    >查看</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
            <!-- <template slot-scope="scope">
              <el-button type="text" @click="detailShow(scope.row)"
                >详情</el-button
              >
            </template> -->
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper" v-if="total > 0">
          <div class="block" style="float: right">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 弹窗 -->
      <pic-detail
        :infoSize="4"
        :infoDetail="parkDetail"
        :rowData="rowData"
        :detailFlag="dialogVisible"
        :tableOrder="tableOrder"
        :parkRecordIdTable="parkRecordIdTable"
        @close="dialogVisible = false"
        :total="total1"
        :tableOrderdata="tableOrderdata"
        :parkRecordIdData="parkRecordIdData"
        @getOrder="clildrenHandleCurrentChange"
        :entryPic="entryPic"
        :exitPic="exitPic"
      ></pic-detail>
    </div>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
import picDetail from "@/components/picDetail/picDetail";
export default {
  data() {
    const start = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
    const end = new Date();
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输入十一个字符"));
      }
    };
    return {
      rule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
      },
      page: 1,
      size: 15,
      // type: "",
      pageSize: 15,
      //        dateRange: [new Date(startTime), new Date(endTime)],
      // dateRange: "",
      dateRange: [dateFormat(start, "yyyy-MM-dd"), dateFormat(end, "yyyy-MM-dd")],
      parkDetail: [],
      rowData: {},
      tableOrder: [
        { label: this.$t("list.order_ID"), value: "payOrderId", width: "" },
        { label: this.$t("list.entry_time"), value: "entryTime", width: "" },
        { label: this.$t("list.Appearance_time"), value: "exitTime", width: "" },
        {
          label: this.$t("list.amount_receivable"),
          value: "shouldPay",
          width: "",
          formatter: (row, column) => {
            return row.shouldPay == 0 ? "0元" : row.shouldPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.preferential_amount"),
          value: "agioPay",
          width: "",
          formatter: (row, column) => {
            return row.agioPay == 0 ? "0元" : row.agioPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.Actual_received_amount"),
          value: "money",
          width: "",
          formatter: (row, column) => {
            return row.money == 0 ? "0元" : row.money / 100 + "元";
          },
        },
        { label: this.$t("list.Payment_Type"), value: "paymentTypeDesc", width: "" },
        { label: this.$t("list.Payment_channels"), value: "channelTypeDesc", width: "" },
        { label: this.$t("list.Payment_devices"), value: "devTypeDesc", width: "" },
        { label: this.$t("list.Payer"), value: "operatorName", width: "" },
        { label: this.$t("list.Payment_yard"), value: "payParkName", width: "" },
        { label: this.$t("list.Third_party_transaction_ID"), value: "tradeNo", width: "" },
      ],
      parkRecordIdTable: [
        {
          label: this.$t("list.Refund_method"),
          value: "refundModeName",
        },
        // {
        //   label: "支付方式",
        //   value: "payTypeName"
        // },
        {
          label: this.$t("list.Payment_channels"),
          value: "channelTypeDesc",
        },
        {
          label: this.$t("list.Payment_devices"),
          value: "devTypeDesc",
        },
        {
          label: this.$t("list.refund_reason"),
          value: "refundReasonName",
        },
        {
          label: this.$t("list.Actual_appearance_time"),
          value: "actualExitTime",
          formatter: (row) => {
            if (row.actualExitTime) {
              return this.timestampToTime(row.actualExitTime);
            } else {
              return "";
            }
          },
        },
        {
          label: this.$t("list.refund_amount"),
          value: "actualRefundPay",
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: this.$t("list.applicant"),
          value: "operatorName",
        },
        {
          label: this.$t("list.Application_Time"),
          value: "createdTime",
        },
        {
          label: this.$t("list.Refund_time"),
          value: "refundTime",
          formatter: (row) => {
            if (row.refundTime) {
              return this.timestampToTime(row.refundTime);
            } else {
              return "";
            }
          },
        },
      ],
      tableOrderdata: [],
      parkRecordId: 0,
      parkRecordIdData: [],
      entryPic: [],
      exitPic: [],
      total1: 0,
      total: 0,
      plateNumber: "",
      loading: false,
      tableData: [],
      tenantList: [],
      dialogVisible: false,
      formInline: {
        parkId: "",
        plateNumber: "",
        carId: "",
        startTime: dateFormat(start, "yyyy-MM-dd"),
        endTime: dateFormat(end, "yyyy-MM-dd"),
      },
      tableCols: [
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
        },
        {
          prop: "parkName",
          label: "所在道路",
          width: "",
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
        },
        {
          prop: "exitTime",
          label: this.$t("list.leaving_time"),
        },
      ],
    };
  },
  components: {
    myComponent,
    picDetail,
  },
  methods: {
    addjkSeting() {
      this.$router.push("/carAlarmSettings");
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      var h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
      var m = date.getMinutes() < 10 ? "0" + date.getMinutes() + ":" : date.getMinutes() + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    // 获取停车场详情图片
    getPic(entryOprNum, exitOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.entryPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
      if (exitOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + exitOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.exitPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
    },
    detailShow(data) {
      this.parkRecordId = data.parkRecordId;
      this.rowData = Object.assign({}, data);
      this.dialogVisible = true;
      this.getRsType(data);
      this.getOrder();
      this.refundOrder();
      //    this.getRefund();
    },

    // 停车记录详情
    getRsType({ parkRecordId }) {
      let opt = {
        url: "/acb/2.0/parkRecord/" + parkRecordId,
        method: "get",
        // data: this.formInline,
        success: (res) => {
          if (res.state == 0) {
            this.parkDetail = res.value;
            this.getPic(res.value.entryOprNum, res.value.exitOprNum);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },

    // 订单记录
    getOrder(pageNum) {
      let page = pageNum || 1;
      let opt = {
        url: "/acb/2.0/payOrder/payOrderByParkRecordId/" + this.rowData.parkRecordId,
        method: "get",
        data: {
          page: page,
          pageSize: this.pageSize,
          parkRecordId: this.rowData.parkRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableOrderdata = res.value;
            this.total1 = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    clildrenHandleCurrentChange(val) {
      this.getOrder(val);
    },

    refundOrder() {
      this.$axios
        .get("/acb/2.0/refundRecord/list", {
          data: {
            page: this.page,
            size: this.size,
            parkRecordId: this.parkRecordId,
            refundState: 5,
          },
        })
        .then((res) => {
          this.parkRecordIdData = res.value.list;
        });
    },
    dateFmt(data) {
      if (data && data.length === 2) {
        let startTime = dateFormat(data[0], "yyyy-MM-dd");
        let endTime = dateFormat(data[1], "yyyy-MM-dd");
        this.formInline.startTime = startTime;
        this.formInline.endTime = endTime;
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      }
    },
    handleCommand(com, data) {
      // if (data.type == 0) {
      //   this.$router.push({ path: "/carpoliceDeck", query: data });
      // } else {
      this.$router.push({ path: "/carpoliceLong", query: data });
      // }
      // this.$router.push({ path: "/carpoliceLong", query: data });
      //        let url = '/acb/2.0/carAlarm/detail/' + data.alarmId
      //        this.$axios.get(url, {
      //          data: {
      //        }
      //      }).then(res => {
      //          this.loading = false;
      //        if (res.state === 0) {
      //          this.CarAlarmVO = res.value.CarAlarmVO;
      //          if (res.value.CarAlarmVO.type === 0) {
      //            this.$router.push({path: '/carpoliceLong', query: data});
      //          } else {
      //            this.$router.push({path: '/carpoliceCover', query: data})
      //          }
      //        }
      //      })
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    lookParkRecordList() {},
    streetChange() {},
    // detailShow(e) {},
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let url = "/acb/2.0/parkRecord/plateNumber/list";
      this.$axios
        .get(url, {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.state == 0) {
              res.value.forEach((v) => {
                v.value = v.plateNumber;
              });
              cb(res.value);
            }
          }
        });
    },
    handleSelect(item) {
      // console.log("--item", item);
      this.formInline.carId = item.carId;
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searParkRecordList();
    },
    /* 所在道路下拉框 */
    getTenantList() {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            operationId: "",
            parkName: "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tenantList = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    searParkRecordList() {
      // let url = "/acb/2.0/carAlarm/query";
      let url = "/acb/2.0/vehicleAlarmRecord/list";
      if (this.formInline.plateNumber.length > 1 && this.formInline.plateNumber.length < 3) {
        this.$message("至少输入三个字符");
        return false;
      }
      this.$axios
        .get(url, {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            carId: this.formInline.carId,
            parkId: this.formInline.parkId,
            startTime: this.formInline.startTime,
            endTime: this.formInline.endTime,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.value && res.value.total > 0) {
              this.total = res.value.total * 1;
              this.tableData = res.value.list;
            } else {
              this.total = 0;
              this.tableData = [];
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  watch: {
    "formInline.plateNumber": {
      handler(curVal, oldVal) {
        if (!curVal) {
          this.formInline.carId = "";
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.searParkRecordList();
    this.getTenantList();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;

}
</style>
